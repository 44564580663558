<template>
  <div>
    <div class="upload-file-btn">
      <el-button type="primary" size="small" class="upload-file-btn" @click="batchUploadFile" icon="el-icon-upload">
        批量上传文件
      </el-button>

      <input type="file" id="batch-file" style="display: none;" @change="batchFileChange" multiple />
    </div>

    <div class="list-item" v-for="(item, index) in list" :key="item.key">
      <svg-icon icon-class="ConfigMap"></svg-icon>
      <div class="content">
        <div class="title">{{ item.key }}</div>
        <div class="desc">{{ item.value }}</div>
      </div>

      <div>
        <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
          <svg-icon icon-class="edit" class-name="handle-icon" @click.native="editItem(item, index)"></svg-icon>
        </el-tooltip>

        <el-tooltip effect="dark" :content="$t('handle.delete')" placement="bottom">
          <svg-icon icon-class="delete" class-name="handle-icon" @click.native="list.splice(index, 1)"></svg-icon>
        </el-tooltip>
      </div>
    </div>

    <div class="list-item" @click="addItem">
      <svg-icon icon-class="add"></svg-icon>

      <div class="content">
        <div class="title">{{ $t("handle.add") }}</div>
        <div class="desc">{{ $t("configMapItem") }}</div>
      </div>
    </div>

    <el-dialog
      :title="$t('configMapItem')"
      :visible.sync="itemDialogVisible"
      top="30px"
      :close-on-click-modal="false"
      append-to-body
      width="80%"
    >
      <el-form size="small" :model="itemForm" label-position="top">
        <el-form-item label="Key">
          <el-input v-model="itemForm.key" />
        </el-form-item>
        <el-form-item label="Value">
          <div class="upload-file-btn">
            <el-button type="primary" size="small" class="upload-file-btn" @click="uploadFile" icon="el-icon-upload">
              {{ isBinary ? "上传二进制文件" : "上传文件" }}
            </el-button>
          </div>

          <input type="file" id="file" style="display: none;" @change="fileChange" />

          <el-input
            type="textarea"
            v-if="isBinary"
            v-model="itemForm.value"
            readonly
            :autosize="{ minRows: 6, maxRows: 10 }"
          >
          </el-input>

          <editor
            v-model="itemForm.value"
            @init="editorInit"
            lang="yaml"
            theme="cobalt"
            width="100%"
            height="500"
            v-else
          >
          </editor>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="itemFormSubmit" size="small">
          {{ itemFormType == "add" ? $t("handle.submit") : $t("handle.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep, includes } from "lodash";
import { readAsText, readAsDataURL } from "utils/file";

export default {
  props: {
    data: {
      type: Object
    },

    isBinary: {
      type: Boolean
    }
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      itemDialogVisible: false,
      list: [],
      itemFormType: "add",
      itemFromIndex: 0,
      itemForm: {
        key: "",
        value: ""
      }
    };
  },

  methods: {
    editorInit() {
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    editItem(item, index) {
      this.itemFormType = "edit";
      this.itemFromIndex = index;
      this.itemDialogVisible = true;
      this.itemForm = cloneDeep(item);
    },

    addItem() {
      this.itemFormType = "add";
      this.itemDialogVisible = true;
      this.itemForm = {
        key: "",
        value: ""
      };
    },

    itemFormSubmit() {
      if (!this.itemForm.key) this.itemForm.key = this.list.length + 1;

      if (this.itemFormType === "add") {
        this.list.push(this.itemForm);
      }

      if (this.itemFormType == "edit") {
        this.list.splice(this.itemFromIndex, 1, this.itemForm);
      }

      this.itemDialogVisible = false;
    },

    uploadFile() {
      document.getElementById("file").click();
    },

    async fileChange() {
      let file = document.getElementById("file").files[0];

      if (file.size / 1024 > 1024) {
        this.$notify.error({
          title: "Error",
          message: "文件大小不能大于1M"
        });

        return;
      }

      this.itemForm.key = file.name;
      let value = this.isBinary ? await readAsDataURL(file) : await readAsText(file);
      this.itemForm.value = value;
    },

    batchUploadFile() {
      document.getElementById("batch-file").click();
    },

    async batchFileChange() {
      let files = document.getElementById("batch-file").files;

      for (let i = 0; i < files.length; i++) {
        let fileName = files[i].name;
        let fileSize = files[i].size;

        if (includes(this.listKeys, fileName)) {
          this.$notify.error({
            title: "Error",
            message: `${fileName}文件已存在，请检查后重新提交`
          });

          return;
        }

        if (fileSize / 1024 > 1024) {
          this.$notify.error({
            title: "Error",
            message: `${fileName}文件大小不能大于1M`
          });

          return;
        }

        let value = this.isBinary ? await readAsDataURL(files[i]) : await readAsText(files[i]);
        this.list.push({
          key: fileName,
          value
        });
      }
    }
  },

  mounted() {
    for (let key in this.data) {
      this.list.push({ key, value: this.data[key] });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },
    listKeys() {
      return this.list.map(item => item.key);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.upload-file-btn {
  margin: 6px 0;
}

.file-name {
  margin-left: 6px;
  font-size: 12px;
}

.binary-file-text {
  height: 300px;
  font-size: 12px;
  line-height: 1;
  overflow-y: hidden;
  border: 1px solid $border-color;
}
</style>
