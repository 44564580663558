export function readAsArrayBuffer(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = function(evt) {
      resolve(evt.target.result);
    };
  });
}

export function readAsDataURL(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function(evt) {
      resolve(evt.target.result.replace(/data.+?;base64,/, ""));
    };
  });
}

export function readAsText(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");

    reader.onload = function(evt) {
      resolve(evt.target.result);
    };
  });
}

export const isUtf8 = async function(file) {
  let res = await readAsArrayBuffer(file);
  let firstCode = new Uint8Array(res)[0];
  return firstCode >= 33 && firstCode <= 126;
};
